<template>
  <div>
    <el-main>
      <div class="user-info">
        <div class="title">1、技师信息</div>
        <div class="content padding between">
          <div class="pContent">
            <span>技师姓名：{{ info.artisan_name }}</span>
            <span>联系电话：{{ info.mobile ? info.mobile : '--' }}</span>
            <span>技师分类：{{ info.type == 1 ? '平台技工' : info.type == 2 ? '小区技工' : info.type == 3 ? '自由入驻技工' : '--' }}</span>
            <span>所属物业：{{ info.mobile ? info.mobile : '--' }}</span>
            <span v-if="info.property_community">所属小区：{{ info.property_community.community_name }}</span>
            <span>注册时间：{{ getDateformat(info.create_time) }}</span>
            <span>
              会员头像：
              <el-image style="width: 60px; height: 60px" :src="info.avatar"></el-image>
            </span>
          </div>
          <div class="replenish">
            <div>服务信息</div>
            <div>
              <span>从事维修工作时间：{{ info.years_of_working }}</span>
              <span>职称：{{ info.job_title }}</span>
              <span>服务权限：{{ info.classify_name }}</span>
              <span>
              照片：
              <el-image v-if="info.user" style="width: 60px; height: 60px" :src="info.user.avatar"></el-image>
            </span>
            </div>
          </div>
        </div>
      </div>
      <div class="address">
        <div class="title">
          <span>2.订单信息</span>
        </div>
        <div class="contentTab">
          <el-table :data="info.in" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <el-table-column prop="truename" label="累计预约订单金额" align="center">
              <span style="margin-right: 10px">{{ info.service_order_amount }}</span>
            </el-table-column>
            <el-table-column label="累计预约订单数" align="center">
              {{ info.service_order_sum }} 
              <span style="color: blue" @click="toOrderList">查看</span>
            </el-table-column>
            <el-table-column label="服务评价" align="center">
              {{ info.order_refund_amount }}
              <span style="color: blue" @click="toEvaluate">查看</span>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- <ul class="cardUl">
        <el-dialog title="送积分" :visible.sync="integralShow">
          <li class="cardLi">
            <div class="content cardLiTab">
              <el-form ref="form" :model="form" label-width="130px" label-position="left">
                <el-form-item label="当前积分：">{{ info.integral }}</el-form-item>
                <el-form-item label="变化：">
                  <el-radio-group v-model="type">
                    <el-radio label="增加"></el-radio>
                    <el-radio label="减少"></el-radio>
                    <el-radio label="最终改为"></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="充值数值：">
                  <el-input v-model="integralNum" placeholder="请添加变化数值，增加/减少/改为"></el-input>
                </el-form-item>
                <el-form-item label="备注：">
                  <el-input type="textarea" v-model="form.desc" placeholder="此备注会显示在用户记录和商家后台"></el-input>
                </el-form-item>
              </el-form>
              <div class="formBtn">
                <el-button>取消</el-button>
                <el-button type="primary" @click="getSendIntegral(integralNum)">确定</el-button>
              </div>
            </div>
          </li>
        </el-dialog> -->
      <!-- </ul> -->
    </el-main>
  </div>
</template>

<script>
import { getDateformat } from '@/util/getDate';
export default {
  data() {
    return {
      id: '',
      info: '',
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getUserInfo();
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.$axios
        .post(this.$api.user.artisanInfo, {
          id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            let result = res.result;
            this.info = result;
            this.info.in = [{id:1}];
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    toOrderList() {
      this.$router.push({ path: '/service/orderList' });
    },
    toEvaluate() {
      this.$router.push({ path: '/service/evaluate' }); 
    },
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
</script>
<style lang="less" scoped>
.pContent,
.replenish > div:nth-child(2) {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-left: 100px;
  span {
    width: 25%;
    padding: 20px 0;
  }
}
.replenish {
  width: 100%;
}
.pContent .countVIP {
  width: 140px;
  height: 64px;
  box-shadow: 8px 8px 4px #ccc;
  background-color: yellow;
  padding: 10px;
}

.contentTab {
  margin: 30px 0 0 100px;
}
.el-main {
  background: #fff;
  font-size: 14px;
  .shenri {
    display: flex;
    span {
      margin: 0 5px;
    }
  }
  .title {
    line-height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
  }
  .header {
    display: flex;
    align-items: center;
  }
  .padding {
    padding: 30px 0px 30px 0px;
  }
  .between {
    justify-content: space-between;
    padding: 20px 0px 20px 0px;
    .left {
      display: flex;
    }
  }
  .user-info {
    .content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        height: 50px;
        line-height: 50px;
        margin-right: 64px;
      }
      p:nth-child(1) {
        display: flex;
        align-items: center;
        .el-image {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
        img {
          width: 30px;
          height: 30px;
          margin-left: 10px;
        }
      }
      P:nth-child(6),
      p:nth-child(7) {
        margin: 0;
      }
    }
  }
  .card-info {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      padding-left: 24px;
      background: #f8f9fb;
      p {
        margin-right: 64px;
      }
    }
  }
  .group {
    .content {
      padding: 20px 24px 10px 24px;
      background: #f8f9fb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          border-radius: 6px;
          padding: 0 20px;
          line-height: 36px;
          border: 1px solid;
          margin-right: 30px;
          margin-bottom: 10px;
        }
      }
      .el-button {
        margin-bottom: 10px;
      }
    }
  }
  .data {
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .content {
      display: flex;
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 20px;
        width: 290px;
        height: 105px;
        margin-right: 20px;
        border: 1px solid #edeef0;
        .left {
          p:nth-child(1) {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          p:nth-child(2) {
            color: #979899;
          }
        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .address {
    margin-top: 10px;
  }
  /deep/ .el-dialog__header {
    text-align: left;
  }
  /deep/ .el-dialog__footer {
    text-align: right;
  }
}

.cardUl {
  width: 100%;
  background-color: #fff;
  margin-top: 50px;
  padding: 50px 0;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  li {
    position: relative;
    margin-bottom: 50px;
    padding-bottom: 100px;

    .cardLiTil {
      background-color: #ececec;
      color: gray;
      font-size: 18px;
      height: 50px;
      text-align: center;
      line-height: 50px;
    }

    .cardLiTab {
      padding: 20px;
    }

    .cardTabPage {
      display: flex;
      justify-content: center;

      position: absolute;
      bottom: 20px;
      left: 40%;
    }
  }
}

.formBtn {
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
</style>
